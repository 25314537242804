.SupportqualityWrap {


    .MuiContainer-maxWidthXl {
        padding: 0 12px;
        max-width: 100% !important;
    }

    .mainTitleWrap {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        padding-bottom: 10px;

        .mainTitle {
            h4 {
                color: #071942;
                //font-size: 26px;
                font-size: 1.625rem;
                // font-size: 1.625em;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 0;

                @media only screen and (max-width: 1281px) {
                    font-size: 1.425rem;
                }
            }

            h6 {
                color: rgba(7, 25, 66, 0.50);
                //font-size: 14px;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 0;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.75rem;
                }
            }
        }
    }

    .innerCards {
        display: flex;
        padding: 10px 20px;
        justify-content: space-between;
        height: 130px;

        .textWrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
          
        }

        .numberTxt {
            color: #FFF;
            font-size: 32px;
            font-weight: 600;
            text-align: left;

        }
        .smallTxt {
            font-size: 20px;
            color: #FFF;
        }

        .imageWrap {
            align-items: center;
            display: flex;

            svg {
                width: 36px;
                height: 36px;
                color: #fff;
            }
        }

        &.orangeGrad {
            border-radius: 6px;
            background: linear-gradient(294deg, #EAB679 12.9%, #EF9E42 96.5%);
            box-shadow: -3px -4px 20px 0px #EDAC63 inset, 0px 10px 20px 0px rgba(237, 172, 99, 0.16);

        }

        &.purpleGrad {
            border-radius: 6px;
            background: linear-gradient(294deg, #CFBDF3 12.9%, #B18DF8 96.5%);
            box-shadow: -3px -4px 20px 0px #C1A6F6 inset, 0px 10px 20px 0px rgba(193, 166, 246, 0.16);
        }

        &.blueGrad {
            border-radius: 6px;
            background: linear-gradient(294deg, #75B1E9 12.9%, #4696E4 96.5%);
            box-shadow: -3px -4px 20px 0px #5FA5E7 inset, 0px 10px 20px 0px rgba(95, 165, 231, 0.16);
        }

        &.greenGrad {
            border-radius: 6px;
            background: linear-gradient(294deg, #6CCCB4 12.9%, #32C49E 96.5%);
            box-shadow: -3px -4px 20px 0px #53C9AB inset, 0px 10px 20px 0px rgba(83, 201, 171, 0.16);
        }
    }
    .tableTitle{
        font-size: 1.1rem;
    color: rgb(51, 51, 51);
    font-weight: bold;
    margin: 0px 16px 8px 0;
    }
}
.MuiTableRow-root td {
svg {
    vertical-align: middle;
}
}


 .commonCard {
        padding: 10px 16px 6px 16px;
        margin-top: 0px;
        border-radius: 6px;
        width: 100%;

        &.chartCard {
            min-height: 350px;
        }

        .tabmenuWrap {
            justify-content: space-between;
        }

        .chartTitle {
            p {
                font-size: 1.1rem;
                color: rgb(51, 51, 51);
                font-weight: bold;
                padding-bottom: 5px;
                border-bottom: 1px solid rgba(162, 159, 159, 0.1215686275);
                margin-bottom: 10px;

                //@media only screen and ( max-height: 720px ) {
                @media only screen and (max-width: 1281px) {
                    font-size: 0.95rem;
                }
            }
        }
        .chartTitle-trend {
            p {
                font-size: 1.1rem;
                color: rgb(51, 51, 51);
                font-weight: bold;
                padding-bottom: 5px;
                margin-bottom: 10px;

                //@media only screen and ( max-height: 720px ) {
                @media only screen and (max-width: 1281px) {
                    font-size: 0.95rem;
                }
            }
        }
        .noDataWrapper{
            position: relative;
            .noDatatext {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            }
        }
        .chartBtnwrap {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                height: 34px;
            }

            .greenText {
                color: #32C49E;

                .blueText {
                    color: #1A73E8;

                }
            }

            .redText {
                color: #E25757;
            }
        }

    }

    .cardGrid {
        width: 25%;
        display: flex;
        flex-direction: row;
        padding: 0 8px;
        margin-bottom: 10px;

        .highlightWrap {
            .highlightGreen {
                color: #32C49E;
                font-family: Rubik;
                //font-size: 12px;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.70rem;
                }
            }

            .highlightRed {
                color: #E25757;
                font-family: Rubik;
                //font-size: 12px;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.70rem;
                }
            }

            .highlightTitle {
                color: #071942;
                //font-size: 16px;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding: 3px 0px 5px 0;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.85rem;
                }
            }

            hr {
                border-bottom: 1px solid #a29f9f1f;
            }

            .innerWrap {
                display: flex;
                padding: 10px 0;

                .highlightNum {
                    display: flex;
                    flex-direction: row;
                    color: #1A73E8;
                    //font-size: 20px;
                    font-size: 2rem;
                    font-style: normal;
                    margin-right: 10px;
                    font-weight: 600;
                    line-height: normal;

                    @media only screen and (max-width: 1281px) {
                        font-size: 1.5rem;
                    }
                }

                .highlightText {
                    display: flex;
                    flex-direction: row;
                    color: rgba(7, 25, 66, 0.50);
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    height: 46px;
                    line-height: normal;

                    @media only screen and (max-width: 1281px) {
                        font-size: 0.75rem;
                    }
                }
            }

            .buttonWrap {
                margin: 10px 0 5px 0;
                display: flex;
                justify-content: end;

                button {
                    color: #000;
                    text-transform: capitalize;
                    height: 34px;
                    font-size: 0.875rem;

                    @media only screen and (max-width: 1281px) {
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }

    .chartLeftwrap {
        display: flex;
    }

    .chartRightwrap {
        justify-content: center;
        
        display: flex;
        border-left: 1px solid rgba(7, 25, 66, 0.08);
        margin: 20px 0;
        flex-direction: column !important;

        .chartrightInner {
            display: flex;
            padding: 10px 0;

            .desc {
                padding: 0 5px;
                color: #071942;
                font-family: Rubik;
                //  font-size: 18px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.90rem;
                }

                &.smallTxt {
                    //font-size: 14px;
                    font-size: 0.875rem;

                    @media only screen and (max-width: 1281px) {
                        font-size: 0.80rem;
                    }
                }
            }

            .percentage {
                font-family: Rubik;
                //  font-size: 18px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.90rem;
                }

                &.greenTxt {
                    color: #45C277;
                }

                &.redTxt {
                    color: #E25757;
                }
            }
        }
    }

    .MuiTableRow-root th {
        text-align: center;
        font-size: 14px;
    }
    .greenBg{
        background: #32C49E;
        padding: 1px;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    margin-left: 5px;
    }
    .orangeBg{
        background: #EF9E42;
        padding: 1px;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    margin-left: 5px;
    }
    .redBg{
        background: #E25757;
        padding: 1px;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    margin-left: 5px;
    }
    .blueBg{
        background: #071942;
        padding: 1px;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    margin-left: 5px;
    }
    .d-flexx{
display: flex;
align-items: center;
    }
    .greenBg.smallText{
        font-size: 9px;
        padding-top: 3px;
    }
    .boldText{
        font-size: 14px;
        font-weight: 600;
        vertical-align: middle;
    }