.leaderboardWrap {


    .MuiContainer-maxWidthXl {
        padding: 0 12px;
        max-width: 100% !important;
    }

    .mainTitleWrap {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        padding-bottom: 10px;

        .mainTitle {
            h4 {
                color: #071942;
                //font-size: 26px;
                font-size: 1.625rem;
                // font-size: 1.625em;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 0;

                @media only screen and (max-width: 1281px) {
                    font-size: 1.425rem;
                }
            }

            h6 {
                color: rgba(7, 25, 66, 0.50);
                //font-size: 14px;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 0;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.75rem;
                }
            }
        }

        .dropdownWrap {
            display: flex;
            justify-content: center;
            gap: 5px;
            align-items: center;

            .dataPickerinput {
                border-radius: 4px;
                border: 1px solid rgba(7, 25, 66, 0.50);
                height: 37px;
                background: transparent;
            }
        }
    }

    .commonCard {
        padding: 10px 16px 6px 16px;
        margin-top: 0px;
        border-radius: 6px;
        width: 100%;

        &.chartCard {
            min-height: 350px;
            &.heightBox{
                min-height: 200px; 
            }
        }

        .tabmenuWrap {
            justify-content: space-between;
        }

        .chartTitle {
            p {
                font-size: 1.1rem;
                color: rgb(51, 51, 51);
                font-weight: bold;
                padding-bottom: 5px;
                border-bottom: 1px solid rgba(162, 159, 159, 0.1215686275);
                margin-bottom: 10px;

                //@media only screen and ( max-height: 720px ) {
                @media only screen and (max-width: 1281px) {
                    font-size: 0.95rem;
                }
            }
        }
        .chartTitle-trend {
            p {
                font-size: 1.1rem;
                color: rgb(51, 51, 51);
                font-weight: bold;
                padding-bottom: 5px;
                margin-bottom: 10px;

                //@media only screen and ( max-height: 720px ) {
                @media only screen and (max-width: 1281px) {
                    font-size: 0.95rem;
                }
            }
        }
        .noDataWrapper{
            position: relative;
            .noDatatext {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            }
        }
        .chartBtnwrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: transparent !important;
           &.jusEnd{
            justify-content:end;
           }
            button {
                height: 34px;
                color: #000;
            }

            .greenText {
                color: #32C49E;

                .blueText {
                    color: #1A73E8;

                }
            }

            .redText {
                color: #E25757;
            }
        }

    }

    .cardGrid {
        width: 25%;
        display: flex;
        flex-direction: row;
        padding: 0 8px;
        margin-bottom: 10px;

        .highlightWrap {
            .highlightGreen {
                color: #32C49E;
                font-family: Rubik;
                //font-size: 12px;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.70rem;
                }
            }

            .highlightRed {
                color: #E25757;
                font-family: Rubik;
                //font-size: 12px;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.70rem;
                }
            }

            .highlightTitle {
                color: #071942;
                //font-size: 16px;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding: 3px 0px 5px 0;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.85rem;
                }
            }

            hr {
                border-bottom: 1px solid #a29f9f1f;
            }

            .innerWrap {
                display: flex;
                padding: 10px 0;

                .highlightNum {
                    display: flex;
                    flex-direction: row;
                    color: #1A73E8;
                    //font-size: 20px;
                    font-size: 2rem;
                    font-style: normal;
                    margin-right: 10px;
                    font-weight: 600;
                    line-height: normal;

                    @media only screen and (max-width: 1281px) {
                        font-size: 1.5rem;
                    }
                }

                .highlightText {
                    display: flex;
                    flex-direction: row;
                    color: rgba(7, 25, 66, 0.50);
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    height: 46px;
                    line-height: normal;

                    @media only screen and (max-width: 1281px) {
                        font-size: 0.75rem;
                    }
                }
            }

            .buttonWrap {
                margin: 10px 0 5px 0;
                display: flex;
                justify-content: end;

                button {
                    color: #000;
                    text-transform: capitalize;
                    height: 34px;
                    font-size: 0.875rem;

                    @media only screen and (max-width: 1281px) {
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }

    .chartLeftwrap {
        display: flex;
    }

    .chartRightwrap {
        justify-content: center;
        align-items: flex-start;
        display: flex;
        border-left: 1px solid rgba(7, 25, 66, 0.08);
        margin: 20px 0;
        display: flex;
        flex-direction: column;

        .chartrightInner {
            display: flex;
            align-items: center;
            padding: 10px 0;

            .desc {
                padding: 0 5px;
                color: #071942;
                font-family: Rubik;
                          font-size: 0.875rem;
                
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.90rem;
                }

                &.smallTxt {
                    //font-size: 14px;
                    font-size: 0.875rem;

                    @media only screen and (max-width: 1281px) {
                        font-size: 0.80rem;
                    }
                }
            }

            .percentage {
                font-family: Rubik;
                //  font-size: 18px;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.90rem;
                }

                &.greenTxt {
                    color: #45C277;
                }

                &.redTxt {
                    color: #E25757;
                }
            }
        }
    }

    @media (min-width: 1920px) {
        .MuiContainer-root {
            // max-width: 1536px;
            max-width: 1900px !important;
            margin-left: 0 !important;
        }
    }

}

.channelWrap {
    margin-top: 10px !important;
    margin-bottom: 0px !important;

    .cardGrid {
        margin-top: 10px;
        display: flex;
        flex-direction: row;

        .highlightWrap {
            width: 100%;
            padding: 6px 12px;
            margin-top: 0px;
            border-radius: 6px;

            .highlightTitle {
                color: #071942;
                //font-size: 16px;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding: 10px 0;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.85rem;
                }
            }

            .innerWrap {
                display: flex;
                padding: 10px 0;
                justify-content: space-between;

                .numberTxt {
                    color: #071942;
                    //font-size: 20px;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                    @media only screen and (max-width: 1281px) {
                        font-size: 1rem;
                    }
                }

                .imageWrap {
                    align-items: center;
                    display: flex;
                }
            }

        }
    }
}

.fixedWrap {
    margin-top: 15px;

    .exportBtnWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top:20px;

        p {
            color: #071942;
            font-size: 1.625rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 0;
           
        }

        .exportIcon {
            color: #071942;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
        }
        .innerExporttwo {
            display: flex;
        }
        .TimeWrap {
            margin-right: 12px;
        }
    }
}

.tableFixedWrapSolvedBy {
    position: fixed;
    width: calc(100% - 330px);
    overflow-y: scroll;
    top: 170px;
    height: calc(100vh - 200px);
}

.tableFixedWrap {
    position: fixed;
    width: calc(100% - 330px);
    top: 280px;
    overflow-y: scroll;
    height: calc(100vh - 280px);
}
.fontWeight {
    font-weight:600;
}
.blackText{
    color: #071942;

}
.blueText{
    color: #1A73E8;

}
.d-flexx{
    display: flex;
}

.chartBtnwrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent !important;
   
    button {
        height: 34px;
        color: #000;
    }

    .greenText {
        color: #32C49E;

        .blueText {
            color: #1A73E8;

        }
    }

    .redText {
        color: #E25757;
    }
}