.customerexperienceWrap {
    .MuiContainer-maxWidthXl {
        padding: 0 12px;
        max-width: 100% !important;
    }

    .mainTitleWrap {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        padding-bottom: 10px;

        .mainTitle {
            h4 {
                color: #071942;
                //font-size: 26px;
                font-size: 1.625rem;
                // font-size: 1.625em;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 0;

                @media only screen and (max-width: 1281px) {
                    font-size: 1.425rem;
                }
            }

            h6 {
                color: rgba(7, 25, 66, 0.50);
                //font-size: 14px;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 0;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.75rem;
                }
            }
        }
    }

    .innerCard {
        background: rgb(245, 245, 245);
        background: linear-gradient(180deg, rgba(245, 245, 245, 1) 0%, rgba(255, 255, 255, 1) 100%);
        margin: 0px;
        padding: 10px;
        border-radius: 6px;
        cursor: pointer;
    }

    .d-flex {
        display: flex;
    }

    .imgWrap {
        background: #fff;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 30px;
        }
    }

    .textWrap {
        display: flex;
        flex-direction: column;
        align-items: self-start;
        margin-left: 10px;

        .companyName {
            color: #071942;
            font-size: 16px;
            font-weight: 600;
        }

        .interactionName {
            color: #07194299;
            font-size: 14px;

            span {
                color: #071942;
            }
        }

    }

    .boldText {
        color: #071942;
        font-weight: 600;
        font-size: 15px;

        &.greenText {
            color: #39C6A1;

        }

        &.redText {
            color: #E25757;


        }

        &.orangeText {
            color: #FF8B58;
        }
    }

    .semiBoldtext {
        color: #071942CC;
        font-size: 12px;
    }

    .marBot15 {
        margin-bottom: 15px;
    }

    .borderLr {
        border-left: 1px solid #07194214;
        border-right: 1px solid #07194214;
    }

}
.headerWrap {
    z-index: 1200 !important;
}
.companyDetails{
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
    span {
        border: 2px solid #1A73E8;
        border-radius: 50px;
        width: 50px;
        height: 50px;
        padding: 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .companyNametext{
font-weight: 400;
font-size: 26px;
    }
    .hourWorked{
        color: #1A73E8;
    }
}
.cardItem {
    justify-content: end;
    align-items: end;
    display: flex;
    margin-top: 10px;
    Button {
        color: #071942;
font-size: 13px;
text-transform: capitalize;
    }
}
.innerdrawCard {
    text-align: left;
    .greenText {
        color: #39C6A1;
        font-weight: 500;
        font-size: 18px;   
    }
    .redText {
        color: #E25757;
        font-weight: 500;
        font-size: 18px;
    }
    .orangeText {
        color: #FF8B58;
        font-weight: 500;
        font-size: 18px;
    }
    .blueText {
        color: #1A73E8;
        font-weight: 500;
        font-size: 18px;
    }
    .interactionText{
font-weight: 400;
font-size: 13px;
color: #07194280;

    }
}
.chartSize {
    width: 50%;
}